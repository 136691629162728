import * as React from 'react';
import { Box } from '@mui/material';
import { useDataRecipients } from '@adatree/atomic-components';

export const Header = () => {
  const { primaryDataRecipient } = useDataRecipients();

  return (
    <>
      <div>
        <Box sx={{ mt: 6, mb: { xs: 2, md: 4 }, display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: { xs: '22rem', md: '30rem' } }}>
            <img style={{ width: '100%' }} src={primaryDataRecipient.logo} alt="Logo" />
          </Box>
        </Box>
      </div>
    </>
  );
};
