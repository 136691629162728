import {
  ConsentAuthorizationApiFactory,
  ConsentResponse,
  CreateConsent,
  ConsentApiFactory,
  DataHolder,
  UseCaseApiFactory,
  UseCaseResponse,
  Logger,
  TokensApiFactory,
  UpdateConsentConsumer,
  Configuration,
  ConfigurationParameters,
  Status,
} from '@adatree/atomic-components';
import { v4 as uuidv4 } from 'uuid';
import { AuthUtil } from '../../../../utils/auth/auth.util';
import { AppSettings } from '../../../settings/app.settings.types';
import { CONSTANTS } from '../../../consts/app.const';
import { State } from '../../../state/state';
import { APP_FLAGS } from '../../../consts/app.flags';
import ConsentRepository from '../../types/consent-repository';

class BackendConsentRepository implements ConsentRepository {
  urlBase: string;
  consentConfig: Configuration;
  dataHolderConfig: Configuration;
  appSettings: AppSettings;

  constructor(appSettings: AppSettings) {
    this.appSettings = appSettings;
    this.urlBase = `${this.appSettings.api.backendProtocol}://${this.appSettings.api.backendDomain}`;

    const consentConfigParameters: ConfigurationParameters = {
      basePath: this.urlBase,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };

    const dataHoldersConfigParameters: ConfigurationParameters = {
      basePath: this.urlBase,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };

    this.consentConfig = new Configuration(consentConfigParameters);
    this.dataHolderConfig = new Configuration(dataHoldersConfigParameters);
  }

  async findAllConsents(): Promise<ConsentResponse[]> {
    this.consentConfig.accessToken = await AuthUtil.getAccessToken();

    const flag = localStorage.getItem(APP_FLAGS.limitGetConsents);

    if (flag === 'true') {
      Logger.warn('Calling findAllConsents limited by revoke dates.');
      const startDate = new Date();
      startDate.setDate(startDate.getDate());
      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1);

      const limitedResponse = await ConsentApiFactory(this.consentConfig, this.urlBase).findAllConsents(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        startDate.toISOString(),
        endDate.toISOString()
      );
      const consents: ConsentResponse[] = limitedResponse.data;
      return consents;
    } else {
      const response = await ConsentApiFactory(this.consentConfig, this.urlBase).findAllConsents();
      const consents: ConsentResponse[] = response.data;
      return consents;
    }
  }

  async findAllActiveConsents(): Promise<ConsentResponse[]> {
    this.consentConfig.accessToken = await AuthUtil.getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).findAllConsents(
      undefined,
      undefined,
      undefined,
      Status.Active
    );
    const consents: ConsentResponse[] = response.data;
    return consents;
  }

  /**
   * @deprecated
   */
  async findAllDataHolders(): Promise<DataHolder[]> {
    return Promise.reject();
  }

  async findAllUseCases(): Promise<UseCaseResponse[]> {
    this.consentConfig.accessToken = await AuthUtil.getAccessToken();
    const response = await UseCaseApiFactory(this.consentConfig, this.urlBase).findAllUseCases(true);

    return Promise.resolve(
      response.data.sort((a, b) => {
        if (a.priority && b.priority) {
          return a.priority - b.priority;
        } else {
          return 0;
        }
      })
    );
  }

  async createConsent(consent: CreateConsent): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await AuthUtil.getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).createConsent(
      undefined,
      undefined,
      consent
    );
    const consentResponse: ConsentResponse = await response.data;
    return consentResponse;
  }

  async updateConsent(consentId: string, consent: UpdateConsentConsumer): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await AuthUtil.getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).updateConsent(consentId, consent);
    if (response.status === 200) {
      return this.findConsentById(consentId);
    }
    return Promise.reject(`Could not update consent. Server responded with: ${response.status}`);
  }

  async revokeConsent(consentId: string): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await AuthUtil.getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).revokeConsent(consentId);
    if (response.status === 200) {
      return this.findConsentById(consentId);
    }
    return Promise.reject(`Could not update consent. Server responded with: ${response.status}`);
  }

  async findConsentById(consentId: string): Promise<ConsentResponse> {
    this.consentConfig.accessToken = await AuthUtil.getAccessToken();
    const response = await ConsentApiFactory(this.consentConfig, this.urlBase).findConsent(consentId);
    const consentResponse: ConsentResponse = await response.data;
    return Promise.resolve(consentResponse);
  }

  async authorization(dataHolderBrandId: string, consentId: string, cdrArrangementId?: string): Promise<string> {
    const state = uuidv4();
    const accessToken = await AuthUtil.getAccessToken();

    // Store the state and redirect Uri for future security check
    sessionStorage.setItem(state, this.appSettings.api.adhRedirectUri);
    sessionStorage.setItem(CONSTANTS.storageKeys.authState, state);
    sessionStorage.setItem(CONSTANTS.storageKeys.authType, State.getAuthType());

    const parametersConfiguration: ConfigurationParameters = {
      accessToken: accessToken,
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    const configuration: Configuration = new Configuration(parametersConfiguration);
    const response = await ConsentAuthorizationApiFactory(configuration, this.urlBase).getAuthorizationForConsent(
      consentId,
      state,
      this.appSettings.api.adhRedirectUri
    );

    const redirect = response.data;

    Logger.debug('ConsentAuthorizationApiFactory redirect ', redirect);

    if (response.status === 200 && redirect.uri) {
      return Promise.resolve(redirect.uri);
    }
    return Promise.reject('Could not process the request');
  }

  async processAuthorization(state: string, code: string, idToken: string, response?: string): Promise<string> {
    const parametersConfiguration: ConfigurationParameters = {
      accessToken: await AuthUtil.getAccessToken(),
      baseOptions: {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    };
    const configuration: Configuration = new Configuration(parametersConfiguration);

    let authorization;
    if (response) {
      authorization = { response };
    } else {
      authorization = {
        code,
        state,
        id_token: idToken,
      };
    }

    Logger.debug('Calling ExternalTokensApiFactory from BackendInfoSecService.processAuthorization');

    const tokenResponse = await TokensApiFactory(configuration, this.urlBase).persistToken(authorization);

    Logger.debug('TokensApiFactory response', tokenResponse, tokenResponse.data);

    if (tokenResponse.status === 201) {
      sessionStorage.removeItem(CONSTANTS.storageKeys.authState);

      // @ts-ignore
      return Promise.resolve(tokenResponse.data.activeConsentId);
    }
    Logger.error('About to fail the create credential flow because the token response was not 201', tokenResponse);
    return Promise.reject('Could not process the request');
  }
}

export default BackendConsentRepository;
