import { FeedbackMessage } from '@adatree/atomic-components';
import Check from 'mdi-material-ui/Check';

export const AuthenticationCallback = () => {
  return (
    <FeedbackMessage
      message="You have successfully logged in."
      icon={<Check sx={{ fontSize: '56px', color: 'primary.main' }} />}
    ></FeedbackMessage>
  );
};
