import * as React from 'react';
import { ConfigLoader } from '../components/organisms/config-loader/config-loader.organism';
import { AppSettingsProvider } from '../providers/app-settings.provider';
import { BootstrapApp } from '../components/organisms/bootstrap/bootstrap.organism';
import { AppSettings } from './settings/app.settings.types';
import { parseConfigResponse } from './configuration/config-response.parser';
import { Logger } from '@adatree/atomic-components';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from '../context/authentication/auth.context';
import { initState } from '../app/state/state';
import { UrlParameter } from '../components/organisms/url-parameter/url-parameter.organism';

const App = () => {
  const [appSettings, setAppSettings] = React.useState<AppSettings>();

  React.useEffect(() => {
    const redirectOverwrites = UrlParameter.getRedirectOverwrites();

    const loadConfig = async () => {
      // load response
      const configRes = await ConfigLoader.loadConfig();
      // parse response
      const appSettings = parseConfigResponse(configRes, redirectOverwrites);
      // save setting
      setAppSettings(appSettings);
    };

    if (window.adtConfig) {
      window.adtConfig.then(
        (configRes) => {
          Logger.debug('Loading config from HTML API call');
          const settings = parseConfigResponse(configRes, redirectOverwrites);

          settings.application.timestamp = process.env.REACT_APP_TIMESTAMP || 'no-timestamp-set';
          settings.application.version = process.env.REACT_APP_VERSION || 'no-version-set';

          setAppSettings(settings);
        },
        (error) => {
          Logger.error(`HTML API call failed: ${error}`);
          loadConfig();
        }
      );
    } else {
      loadConfig();
    }
  }, []);

  initState();

  return (
    <>
      {!appSettings && <p>loading....</p>}
      {appSettings && (
        <AppSettingsProvider settings={appSettings}>
          <BrowserRouter>
            <AuthProvider>
              <BootstrapApp />
            </AuthProvider>
          </BrowserRouter>
        </AppSettingsProvider>
      )}
    </>
  );
};

export default App;
