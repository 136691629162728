import { DataRecipient, DataRecipientType } from '@adatree/atomic-components';
import { RedirectOverwrites } from '../../components/organisms/url-parameter/url-parameter.type';
import { AppSettings } from '../settings/app.settings.types';
import { ConfigResponse } from './config-response.type';

export const parseConfigResponse = (
  configRes: ConfigResponse,
  redirectOverwrites?: RedirectOverwrites
): AppSettings => {
  let dataRecipients = [];

  if (configRes.adr) {
    dataRecipients = [
      mapToDataRecipient(
        configRes.adr,
        DataRecipientType.ACCREDITED_DATA_RECIPIENT,
        configRes.adr.cdrPolicyUrl,
        configRes.adr.complaintEmail
      ),
    ];
  }

  if (configRes.cdrRep) {
    dataRecipients = [
      ...dataRecipients,
      mapToDataRecipient(
        configRes.cdrRep,
        DataRecipientType.CDR_REPRESENTATIVE,
        configRes.adr.cdrPolicyUrl,
        configRes.adr.complaintEmail
      ),
    ];
  }

  if (configRes.taSP) {
    dataRecipients = [
      ...dataRecipients,
      mapToDataRecipient(
        configRes.taSP,
        DataRecipientType.TRUSTED_ADVISER_SERVICE_PROVIDER,
        configRes.adr.cdrPolicyUrl,
        configRes.adr.complaintEmail
      ),
    ];
  }

  if (redirectOverwrites && redirectOverwrites.createConsentRedirect) {
    configRes.consent.successCreateConsentRedirectUrl = redirectOverwrites.createConsentRedirect;
  }
  if (redirectOverwrites && redirectOverwrites.manageConsentRedirect) {
    configRes.consent.successManageConsentRedirectUrl = redirectOverwrites.manageConsentRedirect;
  }
  if (redirectOverwrites && redirectOverwrites.appErrorRedirect) {
    configRes.consent.errorRedirectUrl = redirectOverwrites.appErrorRedirect;
  }

  let appSettings: AppSettings = {
    stage: configRes.stage,
    accreditationNumber: configRes.adr.accreditationNumber,
    api: configRes.api,
    application: configRes.application,
    thirdParty: configRes.thirdParty,
    consent: configRes.consent,
    oidc: configRes.oidc,
    theme: configRes.theme,
    dataRecipients: dataRecipients,
  };

  return appSettings;
};

const mapToDataRecipient = (
  configResDataRecipient,
  type: DataRecipientType,
  cdrPolicyUrl: string,
  complaintEmail: string
): DataRecipient => {
  return {
    cdrPolicyUrl: cdrPolicyUrl,
    complaintEmail: complaintEmail,
    dataSharingRevocationEmail: configResDataRecipient.dataSharingRevocationEmail,
    description: configResDataRecipient.description,
    logo: configResDataRecipient.logo,
    name: configResDataRecipient.name,
    type: type,
  };
};
