import React, { useEffect } from 'react';
import { ErrorMessage, FeedbackMessage } from '@adatree/atomic-components';
import { PageWrapper } from '../../components/atoms/page-wrapper.atom';
import { useAuth } from '../hooks/auth/auth.hook';

interface PrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isLoading && !auth.error && !auth.isAuthenticated) {
      auth.signIn();
    }
  }, [auth]);

  return (
    <>
      {auth.isLoading && (
        <PageWrapper>
          <FeedbackMessage message="Please wait while we send you to your authentication provider." showSpinner />
        </PageWrapper>
      )}
      {auth.error && (
        <PageWrapper>
          <ErrorMessage code="AUTH_001" message={auth.error.message} />
        </PageWrapper>
      )}
      {auth.isAuthenticated && <>{children}</>}
    </>
  );
};
