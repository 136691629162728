import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FeedbackMessage, Logger } from '@adatree/atomic-components';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useConsentRepository } from '../../../app/hooks/repositories/consent-repository/useConsentRepository';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { handleErrorRedirect } from '../../../utils/errors/errors.util';
import { EventName, Tracking } from '../../../utils/tracking/tracking.util';
import { CONSTANTS } from '../../../app/consts/app.const';
import queryString from 'query-string';
import AlertCircle from 'mdi-material-ui/AlertCircle';
import Check from 'mdi-material-ui/Check';

export const ConsentCallback: React.FC = () => {
  const navigate = useNavigate();
  const { hash, search } = useLocation();
  const { appSettings } = useAppSettings();
  const [consentRepository] = useConsentRepository();
  const [isProcessing, setIsProcessing] = useState(true);
  const [isError, setIsError] = useState(false);

  const querySearchParams = queryString.parse(search);
  const response: string = querySearchParams.response as string;

  const queryHashParams = queryString.parse(hash);
  const code: string = queryHashParams.code as string;
  const idToken: string = queryHashParams.id_token as string;
  const state: string = queryHashParams.state as string;
  const error: string = queryHashParams.error as string;
  const errorDescription: string = queryHashParams.error_description as string;

  useEffect(() => {
    const handleSuccess = (id: string) => {
      Tracking.sendEvent({
        name: EventName.landConsentCallbackPage,
      });

      if (appSettings.consent.successCreateConsentRedirectUrl) {
        const redirect = appSettings.consent.successCreateConsentRedirectUrl.replace('[consentId]', id);
        Logger.info(`Redirecting to ${redirect}`);
        window.location.href = redirect;
        setIsProcessing(false);
      } else {
        navigate(URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', id));
      }
    };

    const handleError = (code: string, message: string, error?: Error) => {
      Tracking.sendEvent({
        name: EventName.landConsentCallbackPageWithErrors,
        errors: [code],
      });

      if (error) {
        Logger.error(code, message, error);
      } else {
        Logger.error(code, message);
      }

      if (appSettings.consent.errorRedirectUrl) {
        handleErrorRedirect(appSettings.consent.errorRedirectUrl, code, message);
      }

      setIsError(true);
      setIsProcessing(false);
    };

    const isCsrfSafe = (): boolean => {
      const localState = sessionStorage.getItem(CONSTANTS.storageKeys.authState);
      const currentUri = window.location.origin + window.location.pathname;

      let localRedirectUri;
      let isSafe = false;

      if (localState) {
        localRedirectUri = sessionStorage.getItem(localState);
        sessionStorage.removeItem(localState);
        sessionStorage.removeItem(CONSTANTS.storageKeys.authState);
      }

      Logger.debug('Consent Callback checking for CSRF with values - ', {
        localState: localState,
        localRedirectUri: localRedirectUri,
        state: state,
        code: code,
        idToken: idToken,
      });

      if (localState === state && localRedirectUri === currentUri) {
        isSafe = true;
        Logger.debug('CSRF check passed');
      } else {
        handleError(
          'csrf_err',
          `CSRF check failed. Local state ${localState} and local URI ${localRedirectUri} do not match callback state ${state} and callback URI ${currentUri}`
        );
      }

      return isSafe;
    };

    const callProcessAuthorization = () => {
      consentRepository
        .processAuthorization(state, code, idToken, response)
        .then((id: string) => {
          handleSuccess(id);
        })
        .catch((error) => {
          handleError('pro_auth_err', 'Error calling processAuthorization', error);
        });
    };

    if (error) {
      handleError(error, errorDescription ? errorDescription : `Consent Callback failed due to error ${error}`);
    } else if (response) {
      callProcessAuthorization();
    } else if (isCsrfSafe() && code && idToken && state) {
      callProcessAuthorization();
    } else {
      handleError('mis_para_err', 'Consent Callback did not received all the required hash parameters:');
    }
  }, [
    code,
    idToken,
    state,
    navigate,
    consentRepository,
    appSettings.consent.successCreateConsentRedirectUrl,
    appSettings.consent.errorRedirectUrl,
    error,
    errorDescription,
    response,
  ]);

  return (
    <>
      {isProcessing && <FeedbackMessage message="Processing your response..." showSpinner={true} />}
      {!isProcessing && isError && (
        <FeedbackMessage
          message="Sorry we were not able to process your request. Please try again later."
          icon={<AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />}
        />
      )}
      {!isProcessing && !isError && (
        <FeedbackMessage message="Consent created." icon={<Check sx={{ fontSize: '56px', color: 'primary.main' }} />} />
      )}
    </>
  );
};
