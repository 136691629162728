import * as React from 'react';
import { Container, Typography } from '@mui/material';

export const Footer = () => {
  return (
    <>
      <Container sx={{ my: 1, textAlign: 'center' }}>
        <Typography sx={{ color: 'pageBackground.contrastText' }}>Copyright © Adatree 2022.</Typography>
      </Container>
    </>
  );
};
