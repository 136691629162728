import { useSearchParams } from 'react-router-dom';
import { ConsentCreate } from './consent-create.organism';
import { CONSTANTS } from '../../../app/consts/app.const';

/**
 * This component is the app's edge entry point for the Create Consent flow.
 * It allows us to do initial checks or easily add feature flags for new
 * flow tests and experiments.
 *
 * @returns the Create Consent flow page
 */

export const ConsentCreateEdge = () => {
  const [searchParams] = useSearchParams();
  const useCaseId: string = searchParams.get(CONSTANTS.urlParamsKeys.useCaseId);

  return <ConsentCreate useCaseId={useCaseId} />;
};
