import { ReactNode, useEffect, useState } from 'react';
import {
  ConsentEdit as Edit,
  ConsentResponse,
  FeedbackMessage,
  UpdateConsentRequest,
  UseCaseResponse,
  useConsentForm,
} from '@adatree/atomic-components';
import { Box, Button, Skeleton } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useConsentRepository } from '../../../app/hooks/repositories/consent-repository/useConsentRepository';
import { EventName, Tracking } from '../../../utils/tracking/tracking.util';
import { AlertCircle, Check, ChevronLeft } from 'mdi-material-ui';

type ConsentEditParams = {
  consentId: string;
};

export const ConsentEdit = () => {
  const navigate = useNavigate();
  const [consentRepository] = useConsentRepository();
  const [consentForm] = useConsentForm();
  const { consentId } = useParams<ConsentEditParams>();
  const [consent, setConsent] = useState<ConsentResponse>();
  const [useCase, setUseCase] = useState<UseCaseResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState<ReactNode>();

  useEffect(() => {
    consentRepository.findConsentById(consentId).then((foundConsent: ConsentResponse) => {
      setConsent(Object.assign({}, foundConsent));
    });
  }, [consentId, consentRepository]);

  useEffect(() => {
    if (consent && consent.consentId) {
      consentRepository.findAllUseCases().then((useCases: UseCaseResponse[]) => {
        const foundUseCase = useCases.find((useCase) => {
          return useCase.id === consent.useCase.id;
        });

        setUseCase(Object.assign({}, foundUseCase));
        setIsLoading(false);
      });
    }
  }, [consent, consentRepository]);

  const handleCancel = () => {
    navigate(URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', consentId));
  };

  const handleSubmit = () => {
    Tracking.sendEvent({
      name: EventName.clickConsentUpdate,
    });

    let consent: UpdateConsentRequest = {
      sharingEndDate: consentForm.sharingEndDate.toISOString(),
    };

    const updateConsent = async () => {
      try {
        renderFeedback('Saving your consent request...', true);
        const updatedConsent = await consentRepository.updateConsent(consentId, consent);

        renderFeedback(`Please wait while we redirect you to ${consentForm.dataHolder.brandName}`);
        const redirect = await consentRepository.authorization(
          updatedConsent.dataHolderBrandId,
          updatedConsent.consentId
        );

        window.location.href = redirect;
      } catch (error) {
        renderFeedback('Sorry we were not able to process your request. Please try again later.', false, true);
      }
    };

    updateConsent();
  };

  /**
   * Feedback
   */
  const renderFeedback = (message: string, isLoading = false, isError = false) => {
    const icon =
      isError === true ? (
        <AlertCircle sx={{ fontSize: '56px', color: 'error.main' }} />
      ) : (
        <Check sx={{ fontSize: '56px', color: 'primary.main' }} />
      );

    setFeedback(<FeedbackMessage message={message} icon={icon} showSpinner={isLoading} />);
  };

  return (
    <>
      {isLoading && (
        <>
          <Skeleton height={80} />
          <Skeleton height={80} />
          <Skeleton height={80} />
        </>
      )}
      {!isLoading && (
        <>
          <Box>
            {feedback && feedback}
            {!feedback && consent && (
              <>
                <Button
                  sx={{ mb: 2 }}
                  variant="text"
                  color="inherit"
                  startIcon={<ChevronLeft />}
                  href={URL_SETTINGS.CONSENT_DETAIL.url.replace(':consentId', consentId)}
                >
                  Back
                </Button>
                <Edit
                  consent={consent}
                  useCase={useCase}
                  onCancel={handleCancel}
                  onSubmit={handleSubmit}
                  showSharingDurationsOptions={true}
                />
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
};
