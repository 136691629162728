import { Logger } from '@adatree/atomic-components';

export const enum EventName {
  clickConsent = 'click-consent',
  clickConsentCancel = 'click-consent-cancel',
  clickConsentWithErrors = 'click-consent-with-errors',
  clickConsentUpdate = 'click-consent-update',
  landConsentCallbackPage = 'land-consent-callback-page',
  landConsentCallbackPageWithErrors = 'land-consent-callback-page-with-errors',
  landConsentPage = 'land-consent-page',
}

export interface TrackingEvent {
  name: EventName;
  errors?: string[];
}

const sendEvent = (trackingEvent: TrackingEvent): void => {
  Logger.debug('Send Tracking Event', trackingEvent, window.location.pathname);

  if (window.location.hostname === 'localhost') {
    return;
  }
};

export const Tracking = {
  sendEvent: sendEvent,
};
