import {
  UseCaseResponse,
  ConsentResponse,
  CopyBuilder,
  ConsentCreate,
  Industry,
  useCopy,
  useDataRecipients,
  Images,
  useConsentForm,
  Logger,
} from '@adatree/atomic-components';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { useEffect, useState } from 'react';
import { EventName, Tracking } from '../../../utils/tracking/tracking.util';
import { ConsentCustomData } from '../consent-custom-data/consent-custom-data.organism';
// import { Button } from '@mui/material';
// import ChevronLeft from 'mdi-material-ui/ChevronLeft';

export type ConsentCreateFlowOneUseCaseProps = {
  useCase: UseCaseResponse;
  existingConsents: ConsentResponse[];
  onCancel: () => void;
  onSubmit: () => void;
};

export const ConsentCreateOneUseCase = (props: ConsentCreateFlowOneUseCaseProps) => {
  const { useCase, existingConsents, onCancel, onSubmit } = props;
  const [consentForm, setConsentForm] = useConsentForm();
  const [showCustomDataForm, setShowCustomDataForm] = useState(undefined);
  const { appSettings } = useAppSettings();
  // eslint-disable-next-line
  const [copy, setCopy] = useCopy();
  const { adrDataRecipient, primaryDataRecipient } = useDataRecipients();
  const allowMultiConsents = true;

  let favouriteDataHolders = [];

  favouriteDataHolders = useCase.dataHolders.filter((dataHolder) => {
    // Need to update the API definitions
    //@ts-ignore
    if (dataHolder.favourite) {
      //@ts-ignore
      return dataHolder.favourite === true;
    } else {
      return false;
    }
  });

  favouriteDataHolders = favouriteDataHolders.map((dataHolder) => {
    dataHolder.logoUri = Images.getHighResLogoUri(dataHolder);
    return dataHolder;
  });

  useEffect(() => {
    Tracking.sendEvent({
      name: EventName.landConsentPage,
    });
  }, []);

  useEffect(() => {
    if (useCase.industries && useCase.industries.length > 0 && useCase.industries.includes(Industry.Energy)) {
      setCopy(CopyBuilder.generateCopy(adrDataRecipient, primaryDataRecipient, Industry.Energy));
    } else {
      setCopy(CopyBuilder.generateCopy(adrDataRecipient, primaryDataRecipient, Industry.Banking));
    }

    if (useCase && useCase.customDataSchema) {
      setShowCustomDataForm(true);
    } else {
      setShowCustomDataForm(false);
    }

    // eslint-disable-next-line
  }, [useCase]);

  const handleSubmit = () => {
    onSubmit();
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleCustomDataSubmit = (data) => {
    Logger.debug('Custom Data', data);
    consentForm.customData = data;
    setConsentForm({ ...consentForm });
    setShowCustomDataForm(false);
  };

  return (
    <>
      {showCustomDataForm && (
        <ConsentCustomData defaultValues={consentForm.customData} useCase={useCase} onSubmit={handleCustomDataSubmit} />
      )}
      {!showCustomDataForm && (
        <>
          {/* Need to update the API definitions */}
          {/* {useCase && useCase.customDataSchema && (
            <Button
              sx={{ mb: 2, pl: 0 }}
              variant="text"
              color="inherit"
              onClick={() => {
                consentForm.dataHolder = undefined;
                setConsentForm({ ...consentForm });
                setShowCustomDataForm(true);
              }}
            >
              <ChevronLeft /> Back
            </Button>
          )} */}

          <ConsentCreate
            existingConsents={existingConsents}
            useCase={useCase}
            enablePartnerMessageDiscreetMode={appSettings.consent.enablePartnerMessageDiscreetMode}
            favouriteDataHolders={favouriteDataHolders}
            hideMaxAccountConnectedBackButton={!appSettings.consent.errorRedirectUrl}
            allowMultiConsentsPerDataHolder={allowMultiConsents}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </>
      )}
    </>
  );
};
