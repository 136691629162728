import { useEffect, useState } from 'react';
import { ConsentTabs, ConsentResponse, Logger, useDataRecipients, LinkExternal } from '@adatree/atomic-components';
import { Box, Button, Typography } from '@mui/material';
import { useConsentRepository } from '../../../app/hooks/repositories/consent-repository/useConsentRepository';
import { useAppSettings } from '../../../providers/app-settings.provider';
import ChevronLeft from 'mdi-material-ui/ChevronLeft';

export const ConsentList = () => {
  const [consentRepository] = useConsentRepository();
  const [consents, setConsents] = useState<ConsentResponse[]>();
  const [isLoading, setIsLoading] = useState(true);
  const { appSettings } = useAppSettings();
  const { primaryDataRecipient } = useDataRecipients();

  useEffect(() => {
    consentRepository.findAllConsents().then((consentResponses: ConsentResponse[]) => {
      setConsents(consentResponses);
      setIsLoading(false);
    });
  }, [consentRepository]);

  const handleClick = () => {
    const redirect = appSettings.consent.successManageConsentRedirectUrl;
    Logger.info(`Redirecting to ${redirect}`);
    window.location.href = redirect;
  };

  return (
    <Box>
      {appSettings.consent.successManageConsentRedirectUrl && (
        <Button sx={{ mb: 2 }} variant="text" color="inherit" startIcon={<ChevronLeft />} onClick={() => handleClick()}>
          Back
        </Button>
      )}
      <ConsentTabs consents={consents} isLoading={isLoading} />
      {appSettings.consent.successManageConsentRedirectUrl && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button sx={{ mt: 2 }} variant="contained" onClick={() => handleClick()}>
            Done
          </Button>
        </Box>
      )}
      <Typography variant="body2" sx={{ mt: 4 }}>
        Please read our <LinkExternal href={primaryDataRecipient.cdrPolicyUrl} text={'Consumer Data Right Policy'} />{' '}
        for more information on how {primaryDataRecipient.name} handles your data.
      </Typography>
    </Box>
  );
};
