import {
  Alert,
  Box,
  Container,
  Divider,
  Drawer,
  Fab,
  Link,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { NewFeature } from '@adatree/atomic-components';
import { useFeatureFlag } from '../../../app/hooks/feature-flag/feature-flag.hook';
import { APP_FLAGS } from '../../../app/consts/app.flags';
import { useAuth } from '../../../app/hooks/auth/auth.hook';
import ChevronDoubleDown from 'mdi-material-ui/ChevronDoubleDown';

export const TestModeMenu = () => {
  const auth = useAuth();
  const [getFlag, setFlag] = useFeatureFlag();
  const { appSettings } = useAppSettings();
  const [open, setOpen] = useState(false);
  const [openHint, setOpenHint] = useState(!getFlag(APP_FLAGS.hideTestModeHint));

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    console.log('In Open');
    setOpen(false);
  };

  const handleLogout = () => {
    auth.signOut();
  };

  const RenderLinks = () => {
    const links = [
      { label: 'Create a new consent', url: URL_SETTINGS.CONSENT_CREATE.url },
      { label: 'Manage your consents', url: URL_SETTINGS.CONSENT_LIST.url },
      { label: 'Test Lab', url: URL_SETTINGS.TEST_LAB.url },
    ];
    return (
      <>
        {links.map((item) => {
          return (
            <ListItem key={item.url}>
              <ListItemButton sx={{ p: 0 }}>
                <Link
                  sx={{ px: 2, py: 1, width: '100%', color: 'inherit' }}
                  underline="none"
                  href={item.url}
                  onClick={() => handleClose()}
                >
                  {item.label}
                </Link>
              </ListItemButton>
            </ListItem>
          );
        })}
      </>
    );
  };

  return (
    <>
      {appSettings.application.enableTestMode && (
        <>
          <NewFeature
            title={'Test mode enabled'}
            description="Create a new Consent, Logout and more."
            open={openHint}
            placement="left"
            onClose={() => {
              setOpenHint(false);
              setFlag(APP_FLAGS.hideTestModeHint, true);
            }}
          >
            <Fab
              sx={{ position: 'absolute', top: '10px', right: '10px' }}
              color="info"
              aria-label="Test mode menu"
              size="small"
              onClick={() => handleOpen()}
            >
              <ChevronDoubleDown />
            </Fab>
          </NewFeature>

          <Drawer anchor="top" open={open} onClose={() => handleClose()}>
            <Container>
              <Box sx={{ p: 1 }}>
                <Alert severity="info">
                  <Typography variant="body1">
                    You can see this button and menu because 'Test Mode' is enabled. For Production, 'Test Mode' should
                    be disabled.
                  </Typography>
                </Alert>
                <List>
                  {RenderLinks()}
                  <Divider />
                  <ListItem>
                    <ListItemButton onClick={() => handleLogout()}>Logout</ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Container>
          </Drawer>
        </>
      )}
    </>
  );
};
