import { ConfigJsonLoader } from './config-json-loader.organism';
import { Logger } from '@adatree/atomic-components';
import { ConfigResponse } from '../../../app/configuration/config-response.type';

const loadConfig = async (): Promise<ConfigResponse> => {
  Logger.debug('Loading S3 config');
  const s3Config = await ConfigJsonLoader.fetchConfig('/app-config.json');

  if (s3Config.useApiConfig && s3Config.useApiConfig === true) {
    Logger.debug('S3 config.useApi set to true. Loading API config');
    return await ConfigJsonLoader.fetchConfig('/dashboard-config');
  }

  return s3Config;
};

export const ConfigLoader = {
  loadConfig,
};
