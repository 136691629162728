import { Box, Container, Stack } from '@mui/material';
import { UrlItem } from '../../app/settings/url.settings';
import { useAppSettings } from '../../providers/app-settings.provider';
import { TestModeMenu } from '../molecules/test-mode-menu/test-mode-menu.molecule';
import { Breadcrumb } from './breadcrumb/breadcrumb.atom';

interface PageWrapperProps {
  children: React.ReactNode;
  breadcrumbs?: UrlItem[];
  pageAction?: React.ReactNode;
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export const PageWrapper = (props: PageWrapperProps) => {
  const { children, breadcrumbs, pageAction, width = 'md' } = props;
  const { appTheme } = useAppSettings();

  return (
    <>
      <Box component={'main'} sx={{ my: 0, mx: { xs: 0, sm: 4, md: 2 } }}>
        <TestModeMenu />
        <Container
          maxWidth={width}
          sx={{
            my: { xs: 2, md: 2, lg: 3 },
            px: { xs: 2, md: 4, lg: 5 },
            pb: 2,
            pt: { xs: 2, sm: 3, md: 4 },
            backgroundColor: appTheme.colour.backgrounds.modal,
            borderRadius: '4px',
            position: 'relative',
            minHeight: '1px',
          }}
        >
          {breadcrumbs && (
            <Stack
              sx={{ pt: 1, pb: 3, alignItems: 'center' }}
              direction="row"
              spacing={{ xs: 1 }}
              justifyContent="space-between"
              minHeight={'72px'}
            >
              <Breadcrumb breadcrumbs={breadcrumbs} />
              {pageAction}
            </Stack>
          )}
          {children}
        </Container>
      </Box>
    </>
  );
};
