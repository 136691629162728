import { useState } from 'react';
import { Alert, AlertColor, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAppSettings } from '../../../providers/app-settings.provider';
import { URL_SETTINGS } from '../../../app/settings/url.settings';
import { useAuth } from '../../../app/hooks/auth/auth.hook';
import { useAuthContext } from '../../../context/authentication/auth.context';
import { DataRecipientType, Logger, useDataRecipients } from '@adatree/atomic-components';
import { AuthUtil } from '../../../utils/auth/auth.util';
import { handleError } from '../../../utils/errors/errors.util';
import { CONSTANTS } from '../../../app/consts/app.const';
import axios from 'axios';
import LoadingButton from '@mui/lab/LoadingButton';

interface OtpSignInPinProps {
  email: string;
}

export const OtpSignInPin = (props: OtpSignInPinProps) => {
  const { email } = props;
  const { appSettings } = useAppSettings();
  const { primaryDataRecipient, addDataRecipient } = useDataRecipients();
  const auth = useAuth();
  const authContext = useAuthContext();
  const navigate = useNavigate();

  const [pin, setPin] = useState('');
  const [pinMsg, setPinMsg] = useState(`We have sent a One-time Password to ${email}.`);
  const [pinSeverity, setPinSeverity] = useState<AlertColor>('success');
  const [pinError, setPinError] = useState(false);
  const [pinErrorMsg, setPinErrorMsg] = useState('');

  const [fetching, setFetching] = useState(false);

  const handleSubmitOtp = async () => {
    if (!pin) {
      setPinError(true);
      setPinErrorMsg('Please enter a valid One-time Password.');
    } else {
      setFetching(true);

      const url = `https://${appSettings.api.backendDomain}/signin`;

      let data = {};

      if (authContext.granteeId) {
        data = { email: email, otp: pin, granteeId: authContext.granteeId };
      } else {
        data = { email: email, otp: pin };
      }

      try {
        const response = await axios.post(url, data);
        setFetching(false);

        if (!response.data && response.data.access_token) {
          throw new Error(`Error: access_token was not returned from ${url}`);
        }

        const otpSignInResponse = AuthUtil.parseOtpSignInResponse(response.data, authContext.granteeId);
        auth.postSignIn(otpSignInResponse, authContext.granteeId);

        if (authContext.granteeId) {
          addDataRecipient({
            cdrPolicyUrl: primaryDataRecipient.cdrPolicyUrl,
            complaintEmail: primaryDataRecipient.complaintEmail,
            dataSharingRevocationEmail: primaryDataRecipient.dataSharingRevocationEmail,
            description: '',
            logo: primaryDataRecipient.logo,
            name: otpSignInResponse.grantee.name,
            type: DataRecipientType.GRANTEE,
          });
        }

        const returnUri = localStorage.getItem(CONSTANTS.storageKeys.returnUri);

        if (returnUri) {
          localStorage.removeItem(CONSTANTS.storageKeys.returnUri);
          navigate(returnUri);
        } else {
          navigate(URL_SETTINGS.CONSENT_CREATE.url);
        }
      } catch (error) {
        handleErrors(error);
      }
    }
  };

  const handleResendOtp = async () => {
    setFetching(true);

    const url = `https://${appSettings.api.backendDomain}/otp`;
    const data = {
      email: email,
    };

    try {
      await axios.post(url, data);
      setPinSeverity('success');
      setPinMsg(`A new One-time Password has been sent to ${email}`);
      setFetching(false);
    } catch (error) {
      handleErrors(error);
    }
  };

  const handleErrors = (error) => {
    setFetching(false);

    if (error.response) {
      Logger.error('Server Error calling OTP', error.response);
      if (error.response.status === 401) {
        setPinSeverity('error');
        setPinMsg(
          'The One Time Password is not valid. Please re-enter the One Time Password or click the "Resend One-time Password" button.'
        );
      }
    } else if (error.request) {
      handleError('Client Error calling OTP', error);
      navigate(URL_SETTINGS.ERROR.url);
    } else {
      handleError('Error calling OTP', error);
      navigate(URL_SETTINGS.ERROR.url);
    }
  };

  return (
    <>
      <Alert sx={{ m: 2, width: '100%' }} severity={pinSeverity}>
        {pinMsg}
      </Alert>
      <TextField
        sx={{ m: 2 }}
        id="Pin"
        label="One-time Password"
        variant="outlined"
        value={pin}
        helperText={pinErrorMsg}
        error={pinError}
        onChange={(e) => setPin(e.target.value)}
        onFocus={() => {
          setPinError(false);
          setPinErrorMsg('');
        }}
      />
      <LoadingButton
        sx={{ m: 2, maxWidth: '20rem' }}
        loading={fetching}
        color="button"
        variant="contained"
        onClick={handleSubmitOtp}
      >
        Sign in
      </LoadingButton>

      <Button sx={{ m: 2 }} variant="text" color="inherit" onClick={handleResendOtp}>
        Resend One-time Password
      </Button>
    </>
  );
};
